// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-baremetal-js": () => import("./../../../src/templates/baremetal.js" /* webpackChunkName: "component---src-templates-baremetal-js" */),
  "component---src-templates-colocation-js": () => import("./../../../src/templates/colocation.js" /* webpackChunkName: "component---src-templates-colocation-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-vps-js": () => import("./../../../src/templates/vps.js" /* webpackChunkName: "component---src-templates-vps-js" */)
}

